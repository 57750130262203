<template>
  <div>
    <h1 class="text-h4">Экспорт</h1>
    <v-divider class="my-2"></v-divider>
    <v-card>
      <v-card-title>
        <v-icon left>mdi-google-my-business</v-icon>
        Google Merchant
      </v-card-title>
      <v-card-text>
        Чтобы получить данные в формате Google Merchant - воспользуйтесь соответствующей кнопкой.
        Дополнительно вы можете задать параметры, которые будут добавлены к каждой ссылке на товар.
        Следующие UTM метки для трекинга будут добавлены автоматически в зависимости от выбранной цели:
        <ul class="mt-1 caption">
          <li>Google Merchant: utm_source=google&utm_medium=merchant</li>
        </ul>
        <v-select
          v-model="target"
          class="mt-2"
          label="Цель"
          :items="targetOptions"
          return-object
        />
        <v-text-field
          v-model="urlParams"
          label="Дополнительные параметры ссылки каждого товара."
          hint="Укажите цепочку параметров url без знака ? в начале строки, он добавится автоматически"
          persistent-hint
        />
      </v-card-text>
    </v-card>

    <v-card class="mt-2">
      <v-card-title>
        <v-icon left>
          mdi-download-outline
        </v-icon>
        Скачать
      </v-card-title>
      <v-card-text>
        Получить в виде файла или открыть во вкладке браузера
      </v-card-text>
      <v-card-actions>
        <v-btn @click="openInTab">
          <v-icon left>
            mdi-open-in-new
          </v-icon>
          Открыть в браузере
        </v-btn>
        <v-btn @click="downloadFile">
          <v-icon left>
            mdi-file-download-outline
          </v-icon>
          Скачать файл
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="mt-2">
      <v-card-title>
        <v-icon left>
          mdi-cloud-outline
        </v-icon>
        Разместить выгрузку в облаке
      </v-card-title>
      <v-card-text>
        Вы можете сделать выгрузку в облако и использовать ссылку для доступа к прайслисту:
        <div class="ma-2">
          <ul>
            <li v-for="t in targetOptions" :key="t.value">
              {{ t.text }}: <a target="_blank" :href="priceListUrl(t.filename)">{{ priceListUrl(t.filename) }}</a>
            </li>
          </ul>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="exportToCdn">
          <v-icon left>
            mdi-cloud-arrow-up-outline
          </v-icon>
          Закачать на CDN
        </v-btn>
        <v-btn color="error" @click="deleteFromCdn">
          <v-icon left>
            mdi-cloud-minus-outline
          </v-icon>
          Удалить с CDN
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="busy">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { apiUrl, cdnUrl } from '@/utils/ServiceUrls'
import axios from 'axios'
import ToastService from '@/service/ToastService'

export default {
  ...dashboardPageCommonAttributes,
  name: 'GoogleMerchantExportPage',
  data: function () {
    return {
      busy: false,
      urlParams: null,
      targetOptions: [
        { text: 'Google Merchant', value: 'GoogleMerchant', filename: 'google-price-list.xml' }
      ],
      target: null
    }
  },
  created() {
    this.target = this.targetOptions[0]
  },
  methods: {
    apiUrl,
    buildRequestParams() {
      let params = ''

      if (this.target && this.target.value !== '') {
        params += '&target=' + encodeURIComponent(this.target.value)
      }

      if (this.urlParams && this.urlParams !== '') {
        params += '&urlParams=' + encodeURIComponent(this.urlParams)
      }

      return params
    },
    downloadFile() {
      this.busy = true
      axios.get(apiUrl('/api/v1/google/price-list?output=file' + this.buildRequestParams()), {
        responseType: 'blob'
      }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data)

        // create "a" HTML element with href to file & click
        const link = document.createElement('a')

        link.href = href
        link.setAttribute('download', 'price-list.xml') //or any other extension
        document.body.appendChild(link)
        link.click()

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link)
        URL.revokeObjectURL(href)
      })
        .finally(() => this.busy = false)
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
    },
    openInTab() {
      this.busy = true
      axios.get(apiUrl('/api/v1/google/price-list?output=file' + this.buildRequestParams()), {
        responseType: 'blob'
      }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data)

        // create "a" HTML element with href to file & click
        const link = document.createElement('a')

        link.href = href
        link.target = '_blank'
        // link.setAttribute('download', 'price-list.xml') //or any other extension
        document.body.appendChild(link)
        link.click()

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link)
        URL.revokeObjectURL(href)
      })
        .finally(() => this.busy = false)
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
    },
    exportToCdn() {
      this.busy = true
      axios.get(apiUrl('/api/v1/google/price-list?output=cdn' + this.buildRequestParams()))
        .then((result) => {
          const { url } = result.data

          ToastService.success('Данные сохранены')
        })
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
        .finally(() => this.busy = false)
    },
    deleteFromCdn() {
      this.busy = true
      axios.delete(apiUrl('/api/v1/google/price-list?output=cdn' + this.buildRequestParams()))
        .then(() => {
          ToastService.success('Данные сохранены')
        })
        .finally(() => this.busy = false)
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
    },
    priceListUrl(filename) {
      return cdnUrl('/export/' + filename)
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Экспорт - Yandex Market'
      }
    }
  }
}
</script>
